<template>
     <div class="">
        <div class="md-layout flex align-center mt-2 justify-between">
            <div>
               <h2 class="text-xl text-blue">{{ chapterTitle }}</h2>
            </div>
        </div>
        
        <div class="mt-4 w-100p border-b border-solid border-gray-300 pb-10">
           <p class="text-justify text-sm line-height-27" v-html="chapterDescription"></p>
        </div>

        <div v-for="(part, index) in parts" :key="index" class="mt-10">
            <h2 class="text-base">{{index + 1}}. {{ part.title }}</h2>
            <div class="ml-3 mt-2" v-for="(section, i) in part.sections" :key="i">
                <h2 class="text-base text-capitalize">{{ index + 1 }}.{{i + 1}}. {{ section.type }}</h2>
                <p class="mt-2 ml-2 w-85p text-sm line-height-27" v-html="section.instructions"></p>
                <div class="ml-2">
                    <ChapterAudio v-if="section.type === 'material' && section.material_type === 'audio'" :item="section.material_url"/>
                    <Videos v-else-if="section.type === 'material' && section.material_type === 'video'" :item="section.material_url" :video_resource_path="section.material_url" />
                    <ImageAndContents v-else-if="section.type === 'material' && section.material_type === 'pdf'" :type="section.material_type" :items="section" :url="section.material_url"/>
                    <ImageAndContents v-else-if="section.type === 'material' && section.material_type === 'image'" :type="section.material_type" :items="section" :url="section.material_url"/>
                    <Button 
                        v-else-if="section.type === 'button'" 
                        :label="section.button_name" 
                        class="mx-0 mt-4 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
                        @click="window.open(section.button_url, '_blank');"
                    >
                    </Button>

                    <div v-else-if="section.type === 'task'" class="flex justify-between w-100p flex-wrap">
                        <ImageAndContents class="w-48p mr-1" 
                            v-for="(task, j) in section.tasks" 
                            :key="j"  
                            :items="task"
                            :type="''" 
                        />
                    </div>
                </div>
                <!-- <components :is="item.name" :items="item"></components> -->
            </div>
        </div>

        <section class="w-100p" v-if="isShowActionBtn">
            <div class="flex align-center justify-end mt-6">
                <md-button 
                    @click="goBack"
                    class="px-5 py-2 h-12 text-base text-transform-none font-semibold text-gray-500 rounded-lg"
                >
                    Back 
                </md-button>
                <Button 
                    label="Save & Next" 
                    type="submit" 
                    :loading="loading"
                    class="mr-0 px-5 py-2 h-12 bg-algal text-base font-semibold text-white rounded-lg"
                    @click.native="onSubmit()"
                >
                    Save & Next
                </Button>
            </div>
        </section>
    </div>
</template>

<script>
import ChapterAudio from "@/components/molecule/online-class/items/Audio.vue";
import ImageAndContents from "@/components/molecule/online-class/items/ImageAndContents.vue";
import Videos from "@/components/molecule/online-class/items/Video.vue";
import { Button } from "@/components";
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
    components: {
        ChapterAudio,
        ImageAndContents,
        Videos,
        Button
    },
    props: {
        item: {
            type: Object,
            default: null,
        },
        isShowActionBtn: {
            type: Boolean,
            default: true
        }
    },  
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapGetters({
			getClassInfo: "onlineClass/getClassInfo",
		}),
        chapterTitle() {
            if(this.item && this.item.title) {
                return this.item.title;
            }
            return '';
        },
        chapterDescription() {
            if(this.item && this.item.description) {
                return this.item.description;
            }
            return 'No Description';
        },
        parts() {
            return this.item.parts;
        }
    },
    methods: {
        ...mapActions({
			onOnlineClassPublish: "onlineClass/onOnlineClassPublish",
			removeAllClassInfo: "onlineClass/removeAllClassInfo",
		}),
        ...mapMutations({
			setShowSnackbar: "setShowSnackbar",
		}),
        goBack() {
            this.$router.push({
                name: 'onlineClassCreation.review',
            });
        },
        onSubmit() {

            this.loading = true;

            let data = {
                id: this.getClassInfo.id
            }

            this.onOnlineClassPublish(data)
            .then((response) => {
                this.removeAllClassInfo();
                this.setShowSnackbar(response.message);
                this.$router.push({
                    name: 'onlineClassCreation.success',
                })
            })
            .catch((error) => {
                console.log("error", error);
				this.setShowSnackbar(error);
            })
            .finally(() => this.loading = false);
		},
    }
}
</script>