<template>
    <div class="mt-6" v-if="video_resource_path">
        <span v-if="video_resource_path.match('youtube')">
            <iframe 
            id="player" 
            type="text/html" 
            style="width: 100%; height: 55vh !important; !important;"
            :src="videoUrl(video_resource_path)"
            frameborder="0" class="rounded">
            </iframe>
        </span>  
        <span v-if="!video_resource_path.match('youtube')">
            <video controls style="width: 100%;" class="rounded">
            <source :src="video_resource_path" type="video/mp4">
            Your browser does not support HTML5 video.
            </video>
        </span>
        <!-- <b class="text-lg mt-4 block">study guide file.mp4</b> -->
    </div>
</template>

<script>
import { getEmbeddedUrl } from "@/utils/videoHelper";
export default {
    components: {
       
    },
    props:{
      quiz: {
        type: Object,
      },
      video_resource_path: {
        type: String,
        default: "https://www.youtube.com/watch?v=NiQVN_YeWSw&list=RDNiQVN_YeWSw&start_radio=1"
      }
    },
    data() {
      return {
        // video_resource_path: 'https://www.youtube.com/watch?v=NiQVN_YeWSw&list=RDNiQVN_YeWSw&start_radio=1',
      }
    },
    methods: {
      videoUrl(url) {
        return getEmbeddedUrl(url);
      },
    }
}
</script>