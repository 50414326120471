<template>
    <div class="md-layout md-gutter m-5 pb-3 mt-6 mb-0">
        <div class="md-layout-item md-size-100" style="height: 500px; !important">
            <embed v-if="urlType === 'pdf'" class="rounded" style="height: 100%;" :src="url" type="application/pdf" width="100%" height="600px" />
            <img v-else class="rounded-lg w-100p" style="height: 100%;" :src="url"/>
        </div>

        <div class="w-full flex justify-center align-center mt-8 mb-5 mr-2">
            <Button
                class="size-lg text-lg text-capitalize h-12 rounded-lg px-3 text-black mr-0 border border-solid border-gray-500 mr-4"
                label="cancel"
                type="submit"
                @click="$emit('onClose',false)"
                >
            </Button>
        </div>
    </div>
</template>
<script>
import {Button} from "@/components";
export default {
    name: "ShowImageAndPdfModal",
    components: {
        Button
    },
    props: {
        url: {
            type: String,
            default: '',
        },
        urlType: {
            type: String,
            default: '',
        }
    }, 
}
</script>