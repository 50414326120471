<template>
  <div :class="wrapperClass">
    <md-tabs @md-changed="checkNewPosts" ref="mdTabRef">
      <template slot="md-tab" slot-scope="{ tab }">
        <span class="text-capitalize text-bold text-xs">
          {{ tab.label }}
        </span>
      </template>

      <md-tab :class="itemClass" v-for="(item, index) in items"  :key="index" :id="item[titleKey]" :md-label="item[titleKey]" ></md-tab>
    </md-tabs>
  </div>
</template>

<script>
  export default {
    name: 'TabCustomTemplate',
    props: {
      items: {
        type: Array,
        required: true,
      },
      itemClass: {
        type: String,
        default: 'text-semibold'
      },
      titleKey: {
        type: String,
        default: 'name',
      },  
      classes: {
        type: String,
        default: '',
      },
      activeClass: {
        type: String,
        default: '',
      },
      wrapperClass: {
        type: String,
        default: 'c_simple_tab'
      },
      activeTab: {
        type: String,
        default: '',
      }
    },
    data: () => ({
      newPosts: 0,
      checkInterval: null
    }),
    methods: {
      checkNewPosts (activeTab) {
        this.$emit('activeTab', activeTab);
      }
    },
    watch: {
      activeTab: {
        handler(val) {
          if(val) {
            this.$refs.mdTabRef.activeTab = val;
          }
        },
        deep: true
      }
    },
    mounted () {
      this.checkNewPosts();
    }
  }
</script>

<style lang="scss">
.c_simple_tab {
  .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
    color: #fff !important;
    background-color: #265aef !important;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    font-weight: 600;
    font-size: 1rem;
  }
  
  .md-tabs.md-theme-default .md-tabs-navigation .md-button {
    font-weight: 600 !important;
    font-size: 1rem;
  }
}

.c_simple_tab_two {
  display: flex;
  justify-content: center;
  .md-tabs.md-theme-default .md-tabs-indicator {
    display: none;
  }
  .md-tabs {
      max-width: 950px;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
  }
  .md-button.md-active {
    transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1), left 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    border-bottom: 2px solid #661dd0 !important;
  }
  .md-tabs.md-theme-default .md-tabs-navigation .md-button {
    margin-left: 10px;
    margin-right: 10px;
    min-width: none;
    max-width: 500px !important;
    margin-bottom: 10px;
  }
  .md-button:not([disabled]).md-focused::before, .md-button:not([disabled]):active::before, .md-button:not([disabled]):hover::before {
    background-color: transparent !important;
    opacity: 0.12;
  }
  .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
    font-weight: bold;
    // color: #0000 !important;
    font-size: 2rem;
    color: black;
    // border-bottom: 1px solid #661dd0 !important;
    margin-bottom: 10px;
    background: transparent !important;
  }

  .md-focused {
    background: none !important;
  }
  
  .md-tabs.md-theme-default .md-tabs-navigation .md-button {
    font-weight: 600 !important;
    font-size: 2rem;
  }
}

.c_simple_tab_three {
  display: flex;
  justify-content: center;
  .md-tabs.md-theme-default .md-tabs-indicator {
    display: none;
  }
  .md-tabs {
      max-width: 950px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      overflow-x: auto;
  }
  .md-button.md-active {
    transition: right 0.3s cubic-bezier(0.4, 0, 0.2, 1), left 0.35s cubic-bezier(0.4, 0, 0.2, 1);
    border-bottom: 2px solid #000 !important;
  }
  .md-tabs.md-theme-default .md-tabs-navigation .md-button {
    margin-left: 10px;
    margin-right: 10px;
    min-width: none;
    max-width: 500px !important;
    margin-bottom: 10px;
  }
  .md-button:not([disabled]).md-focused::before, .md-button:not([disabled]):active::before, .md-button:not([disabled]):hover::before {
    background-color: transparent !important;
    opacity: 0.12;
  }
  .md-tabs.md-theme-default .md-tabs-navigation .md-button.md-active {
    font-weight: bold;
    // color: #0000 !important;
    font-size: 2rem;
    color: black;
    // border-bottom: 1px solid #661dd0 !important;
    margin-bottom: 10px;
    background: transparent !important;
  }

  .md-focused {
    background: none !important;
  }
  
  .md-tabs.md-theme-default .md-tabs-navigation .md-button {
    font-weight: 600 !important;
    font-size: 2rem;
  }
}

// .tab-component .active {
//   font-weight: bold;
//   border-bottom: 1px solid rgb(73, 73, 73);
//   margin-bottom: -3px;
// }
</style>
