<template>
    <div class="mt-4 chapter-audio">
        <Audio :src="src" :isShowWaveText="isShowWaveText" :isShowWaveDuration="isShowWaveDuration" />
    </div>
</template>

<script>
import Audio from '@/components/molecule/question/audio.vue'
export default {
    props: {
        content: {
            type: Object
        },
        isShowWaveDuration: {
          type: Boolean,
          default: true
        },
        isShowWaveText: {
          type: Boolean,
          default: true
        },
        item: {
          type: String,
          default: null,
        }
    },
    components: {
        Audio
    },
    data() {
      return {
        src: 'https://kazi-blubird.sfo2.digitaloceanspaces.com/apical/speaking/audio/speaking_1658458085802.wav'
      }
    },
    watch: {
      item: {
        handler(val) {
          if(val) {
            this.src = val;
          }
        },
        deep: true,
      }
    }
}
</script>

<style>
  .chapter-audio .md-sl__wave_body{
    background: hsla(0, 0%, 74%, 0);
    padding: 0px;
  }
</style>