
const onlineClassTab = [
    { name: 'LIST',  path: 'onlineClass.online-class-list', icon: 'format_list_bulleted' },
    { name: 'ASSIGN TO BRANCH', path: 'onlineClass.online-class-list1', icon: 'table_view' },
    { name: 'ASSIGN TO TRAINER/ADMIN', path: 'onlineClass.online-class-list2', icon: 'contact_emergency' },
    { name: 'DRAFT', path: 'onlineClass.online-class-list3', icon: 'save' },
    { name: 'COMMUNICATION', path: 'onlineClass.online-class-list4', icon: 'forum' }
];
const onlineclassesChild = [];

for (let i = 0; i < 15; i++) {
    onlineclassesChild.push({
        id: i,
        oc_id: 'STD1124',
        name: 'Mr Jone Doe',
        progress: 25,
        date: '13 Oct. 2022',
    });
}

const onlineClasses = [];

for (let i = 0; i < 15; i++) {
    onlineClasses.push({
        id: i,
        oc_id: 'OC1241',
        thumbal: 'https://images.unsplash.com/photo-1612969308146-066d55f37ccb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGJvb2slMjBjb3ZlcnxlbnwwfHwwfHw%3D&w=1000&q=80',
        title: 'Population of Canada',
        modules: 7,
        chapters: 42,
        duration: 45,
        tags: 'Reading',
        pdf: 'done',
    });
}

const stOnlineClass = [];

for (let i = 0; i < 15; i++) {
    stOnlineClass.push({
        id: i,
        date: '21 Sep, 2020',
        time: '12:00pm',
        title: 'Importance of Listening',
        medium: 'Zoom',
        zoomLink: 'https://zoom.com/xtwz-ymqr-254',
        status: 'upcoming'
    });
}

const steps = [
    {
      id: 1,
      label: 'Class Information',
      complete: false,
      current: true,
      routeName: 'onlineClassCreation.classInformation'
    },
    {
      id: 2,
      label: 'Why This is Important',
      complete: false,
      current: false,
      routeName: 'onlineClassCreation.whyImportant'
    },
    {
      id: 3,
      label: 'FAQ',
      complete: false,
      current: false,
      routeName: 'onlineClassCreation.faq'
    },
    // {
    //   id: 4,
    //   label: 'Class Mapping',
    //   complete: false,
    //   current: false,
    //   routeName: 'onlineClassCreation.classMapping'
    // },
    {
      id: 5,
      label: 'Module Information',
      complete: false,
      current: false,
      routeName: 'onlineClassCreation.moduleInformation'
    },
    {
        id: 6,
        label: 'Chapters Details',
        complete: false,
        current: false,
        routeName: 'onlineClassCreation.chapterDetails'
    },
    {
      id: 7,
      label: 'Rules',
      complete: false,
      current: false,
      routeName: 'onlineClassCreation.rules'
    },
    {
      id: 8,
      label: 'Review',
      complete: false,
      current: false,
      routeName: 'onlineClassCreation.review'
    },
    {
      id: 9,
      label: 'Preview',
      complete: false,
      current: false,
      routeName: 'onlineClassCreation.preview'
    },
]

const modules = []
for(let i = 0; i < 3; i++){
  modules.push({
    id: '1',
    name: 'Module ' + (i + 1),
    classes: [
      {
        id: '1',
        name: 'Class 1'
      },
      {
        id: '2',
        name: 'Class 2'
      },
      {
        id: '3',
        name: 'Class 3'
      }
    ]
  })

}

export default {
    onlineClassTab,
    onlineClasses,
    stOnlineClass,
    onlineclassesChild,
    steps,
    modules
}