<template>
    <div class="border rounded-lg border-solid border-gray-400 px-2 py-4 my-4">
        <div class="md-layout flex align-center mt-1 justify-between p-2">
            <div class="flex align-center"> 
                <span class="mr-4">
                    <!-- <iframe :src="url" style="width:100%;height:700px;"></iframe> -->
                    <!-- <embed v-if="items.type === 'pdf' || type === 'pdf'" class="rounded w-32 h-20" :src="url" type="application/pdf" width="100%" height="600px" /> -->
                    <img v-if="items.type === 'pdf' || type === 'pdf'" class="rounded w-32 h-20" :src="pdfImage"/>
                    <img v-else-if="items.type === 'pdf' || type === 'pdf' || type === 'image'" class="rounded w-32 h-20" :src="url"/>
                    <md-icon v-if="items.type === 'docs' || type === ''" class="text-primary text-4xl">library_books</md-icon>
                </span>    
                <h2 class="text-justify text-sm">{{ items.title }}</h2>
            </div>
            <span class="pointer">
                <md-icon v-if="items.type === 'pdf' || type === 'pdf' || type === 'image'" class="text-blue bg-trans-blue text-lg rounded-full p-4" @click.native="onShowPreview">visibility</md-icon>
                <md-icon v-if="items.type === 'docs' || type === ''" class="text-blue bg-trans-blue text-lg rounded-full p-4">arrow_forward</md-icon>
            </span>
        </div>
        <Dialog  :class="component" v-if="isShowPreview">
            <components :content="content" :url="url" :urlType="type" :is="component" @onClose="onCloseModal"></components>
            <!-- <ShowImageAndPdfModal :url="url" :urlType="type"  /> -->
        </Dialog>
    </div>
</template>

<script>
import {  Dialog } from "@/components";
import pdfDefaultImgae from "@/assets/images/pdf.png";
import ShowImageAndPdfModal from "@/components/molecule/online-class/items/ShowImageAndPdfModal.vue";
import { mapMutations } from 'vuex';

export default {
    components: {
        Dialog,
        ShowImageAndPdfModal
    },
    props: {
        items: {
            type: Object
        },
        url: {
            type: String,
            default: 'https://media.istockphoto.com/id/949118068/photo/books.jpg?s=612x612&w=0&k=20&c=1vbRHaA_aOl9tLIy6P2UANqQ27KQ_gSF-BH0sUjQ730='
        },
        type: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            pdfImage: pdfDefaultImgae,
            component: "",
            isShowPreview: false,
        }
    },
    methods:{
        ...mapMutations({
            dialog: "setShowDialog",
        }),
        onShowPreview() {
            this.component = "ShowImageAndPdfModal";
            this.dialog(true);
            this.isShowPreview = true
        },
        onCloseModal() {
            this.isShowPreview = false;
        }
    }
}
</script>